@media (max-width:2560px) {
    #loginSection {

        top: 35%;
        width: 23%;
    }

    .clearCloseCalander.ToDateClearOpen {
        margin-left: 0;
    }
}

@media (max-width:1920px) {
    #loginSection {
        top: 33%;
        width: 27%;
    }

    .gridIconsList.gridIconsListBottomInfo {
        padding-right: 115px;
    }

    .clearCloseCalander.ToDateClearOpen {
        margin-left: -35px;
    }
}

@media (max-width:1600px) {
    #loginSection {
        top: 38%;
    }

    .gridIconsList.gridIconsListBottomInfo {
        padding-right: 104px;
    }

    .clearCloseCalander.ToDateClearOpen {
        margin-left: -60px;
    }
}

@media (max-width:1440px) {
    .loginOuter .iconTab {

        margin: 0px 5px 45px;
    }

    #loginSection {

        width: 35%;
    }

    .gridIconsList.gridIconsListBottomInfo {
        padding-right: 93px;
    }

    .clearCloseCalander.ToDateClearOpen {
        margin-left: -80px;
    }
}

@media (max-width:1366px) {
    #loginSection {
        top: 52%;
    }

    .loginOuter .iconTab {
        margin: 0px 3px 45px;
    }

    #loginSection {
        width: 32%;
    }

    .gridIconsList.gridIconsListBottomInfo {
        padding-right: 85px;
    }

    .clearCloseCalander.ToDateClearOpen {
        margin-left: -90px;
    }

}

@media (max-width:1330px) {
    .treatmentNewAdd label {
        font-size: 12px;
        padding: 0 8px 0 0;
    }

    body .saVeBtn {
        padding: 4px 10px;
        margin: 0 0 0 2px;
    }
}

@media (max-width:1280px) {
    #loginSection {
        top: 40%;
    }

    .prgnancyDetailsWrap {

        width: 100%;
    }

    .appoinmentReason span.elipsis {
        width: auto;
    }

    #appointmentSpan .dashBoardContentDes .dashBoardContent {
        width: 100%;
    }

    .gridIconItem.gridSmallWidth {
        width: 110px;
    }

    .treatmentPlanHead.EntryFormsSec {
        width: 100%;
    }

    .clearCloseCalander.ToDateClearOpen {
        margin-left: -100px;
    }
}

@media (max-width:1200px) {
    .gridIconItem.gridSmallWidth {
        width: 95px;
    }

    .gridIconsList.gridIconsListBottomInfo {
        padding-right: 70px;
    }

    .labOrderHeadDivPlusName {

        width: 95%;
    }

}

@media (max-width:1024px) {
    .appoinmentWrap {
        display: block;
        padding: 3px;
    }

    .icon.iconCalendar.calendar.plcalendar svg {
        margin-left: 9%;
    }

    .doc.blogVisitPage .blogVisitBlock .blogVisitHead a {
        font-size: 22px;
        line-height: 26px;
    }

    .appoinmentDateDiv {
        width: 100%;
    }

    .borderButton.borderButtonLarge.legoButton_green {
        display: inline-block;
    }

    .aDDTreat #lblMessage {
        left: 200px;
    }

    .contentList ul {
        padding-left: 0;
    }

    #loginSection {
        width: 40%;
    }

    .blockCalendarPopupSec .newAppoinmentRightList.timeSelect {
        width: 25%;
    }

    .contentWrap.members_receive h2 {
        font-size: 20px;
    }

    .contentList ul li {
        font-size: 14px;
    }

    .headerBottomIcon a,
    .headerBottomIcon a:hover {
        padding: 8px 4px 5px;
    }

    .gridIconsList.gridIconsListBottomInfo {
        display: block;
    }

    .gridIconItem.gridSmallWidth {
        width: 140px;
    }

    .gridIconsList.gridIconsListBottomInfo {
        padding-right: 0px;
    }

    .dashboardLeft .appoinmentDateDetail {
        width: 100%;
    }

    .labOrderHeadDivPlusName {
        width: 94%;
    }

    .clearCloseCalander.ToDateClearOpen {
        margin-left: -135px;
    }

    .notification_block {
        right: 24%;
    }

    .gridContent {
        width: 100%;
    }

    .lbOrderNodata {
        width: 90vw;
    }
}

@media (max-width:992px) {
    .toggleHeader {
        display: inline-block;
        position: relative;
        left: -10px;
        width: 38px;
        z-index: 1;
        top: -7px;
        bottom: 0;
        background: rgba(0, 0, 0, 0.25);
        padding: 16px 8px 0;
        height: 54px;
        vertical-align: top;
    }

    .headerRightList img {
        width: 23px;
    }

    .userClick img {
        width: 15px;
    }

    .userClick {
        width: 23px;
        height: 23px;
    }

    .headerRightList {
        padding: 8px 5px;
        font-size: 11px;
    }

    .headerMenu {
        width: 100%;
        /* display: none; */
        opacity: 1;
        position: fixed;
        left: 0;
        background: #2d1152;
        text-align: left;
        bottom: 0;
        z-index: 9999;
        /* height: 100%; */
        padding: 0;
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        -ms-transition: 0.3s all;
        text-align: center;
    }

    .logo {
        position: relative;
        /* left: 32px; */
        left: 0px;
        top: 0;
        display: inline-block;
    }

    .headerRight {
        top: 6px;
        right: 0;
    }

    .logo img {
        max-height: 40px;
    }

    .headerTop {
        padding: 7px 10px;
        height: 54px;
    }

    .toggleHeader span {
        display: block;
        height: 3px;
        background: #e8e8e8;
        position: relative;
        top: 2px;
        width: 20px;
    }

    .toggleHeader span:after,
    .toggleHeader span:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 7px;
        height: 3px;
        background: #e8e8e8;
    }

    .toggleHeader span:after {
        top: 14px;
    }

    .homeUserDiv {
        padding: 15px;
        background: #e8e8e8;
    }

    .homeUserDiv img {
        width: 75px;
        height: 75px;
        margin: 0;
        display: inline-block;
        vertical-align: top;
        border: 2px solid #a5a5a5;
        border-radius: 50%;
        padding: 3px;
        background: #fff;
    }

    .homeUseName span {
        font-size: 13px;
    }

    .homeUseName {
        font-size: 15px;
        display: inline-block;
        width: 75%;
        text-align: left;
        padding: 20px 0 0 20px;
        vertical-align: top;
    }

    .QuickHeadTitle {
        padding: 10px 15px 5px;
        font-size: 14px;
        margin: 0 0 10px;
    }

    .dashMenu {
        padding: 5px 15px;
        margin: 0;
    }

    .dashMenu li {
        padding-bottom: 10px;
        font-size: 12px;
    }

    .dashMenu li img {
        width: 22px;
        margin-right: 10px;
        top: -2px;
    }

    .landingHead {
        font-size: 15px;
    }

    .landingBodyWrap {
        padding: 10px 15px;
    }

    .landingMainContent {
        padding: 15px 0 0px;
    }

    .landingMainContent img {
        margin: 0 0 15px;
    }

    h3 {
        font-size: 14px;
    }

    .landingMainContent p {
        padding: 5px 0;
        font-size: 12px;
    }

    .landingMainContent ul {
        margin: 15px 0 0;
        padding: 0 0 0 30px;
    }

    .landingMainContent ul li {
        padding-bottom: 10px;
        font-size: 12px;
    }

    .lastSection {
        padding: 5px 0;
    }

    .footerContent {
        font-size: 10px;
        width: 100%;
    }

    .footerContent h6 {
        font-size: 15px;
        margin: 0 0 10px;
    }

    .footer {
        padding: 15px 5px 55px;
    }

    .footer-padd {
        padding: 15px 5px 20px;
    }

    .footerContent span {
        margin: 0px 0 0;
    }

    .headerIcon {
        /*display: block; */
        font-size: 0;
        text-align: center;
        width: 16.66%;
    }

    .headerIcon a {
        padding: 13px 0;
        position: relative;
        z-index: 1;
    }

    .headerIcon img {
        display: inline-block;
        width: 22px;
        margin: 0 auto 0
    }

    .headerIconActive:after {
        left: 0;
        right: 0;
        height: 100%;
        /* opacity: 0.2; */
        bottom: 0;
        background: rgba(0, 0, 0, 0.25);
    }

    .userDropDiv {
        z-index: 1;
        top: 40px;
    }

    .headerRightDropList {
        width: 170px;
    }

    .headerRightDropList ul,
    .userDropDiv ul li {
        font-size: 10px;
    }

    .practiceuserName {
        padding: 6px 10px 0;
    }

    .practiceuserEmail {
        padding: 0 10px 6px;
    }

    .landingProfile .accordion__button {
        padding: 10px;
        font-size: 12px;
    }

    .landingProfile .accordion__panel {
        padding: 20px 0;
    }

    body .borderButton,
    body .borderButtonCancel,
    body .borderButtonLarge {
        padding: 3px 20px;
        font-size: 13px;
    }

    body .profileLink {
        color: #4f84fb;
        font-size: 13px;
    }

    .labelTextField,
    body .inputField,
    body .textareaField {
        font-size: 10px;
    }

    body .inputField,
    body .textareaField {
        height: 28px;
        padding: 2px 5px;
    }

    body .textareaField {
        height: 85px;
    }

    .languageIcons {
        right: -10px;
        font-size: 18px;
        line-height: 33px;
    }

    .languageProfileDiv .profileForm {
        padding: 0 25px 0 0;
    }

    .addOnContentList {
        width: 95%;
    }

    .addOnSection {
        padding: 15px 0 0;
    }

    .dashboardtable table th,
    .dashboardtable table td {
        padding: 5px;
        font-size: 10px;
    }

    .homeSpecialityList {
        width: 100%;
        display: block;
        padding: 0 0px 10px 0;
        font-size: 11px;
    }

    .uploadButton label,
    body .mainButton {
        padding: 5px 15px;
        line-height: 17px;
        font-size: 11px;
        height: 28px;
    }

    .uploadButtonIarge label {
        padding: 8px 25px;
        font-size: 13px;
    }

    .uploadThumbImage img {
        width: 40px;
    }

    .uploadTextMessage {
        font-size: 9px;
    }

    .changePasswordDiv .inputField {
        width: 100%;
    }

    .headerBottomIcon img {
        width: 16px;
    }

    .headerBottomIcon a {
        padding: 5px 10px 3px;
    }

    .headerBottomIcon {
        font-size: 10px;
    }

    .headerBottom {
        padding: 5px 0 0;
    }

    .calenderPopupDiv {
        width: 97%;
    }

    body .popupClose {
        line-height: 25px;
    }

    .popUpTitle {
        font-size: 16px;
    }

    .appointmentForm {
        margin: 0 -5px;
    }

    .calenderPopList {
        padding: 0 5px;
        font-size: 10px;
    }

    .calenderFormListSmall {
        margin: 0 10px 0 0;
    }

    .calenderFormListSplitText {
        width: 8%;
    }

    .newAppoinmentButton {
        padding: 10px 5px;
    }

    .breadcumbDiv .breadcrumb {
        padding: 10px 15px;
        font-size: 11px;
    }

    body .allPatientLink {
        font-size: 11px;
    }

    .breadcumbDivRight svg {
        margin: 0 0 0 8px;
        font-size: 11px;
    }

    .breadcumbDivRight {
        padding: 6px 5px;
    }

    .searchSection {
        padding: 0px 0 10px;
    }

    .searchItem {
        width: 50%;
        padding: 7px 7px 0;
    }

    .commonButton img {
        width: 12px;
    }

    body .commonButton {
        padding: 4px 10px;
        line-height: 21px;
        height: 30px;
        margin: 0 5px 0 0;
    }

    .titleSection {
        margin: 10px 0 25px;
    }

    h4 {
        font-size: 12px;
    }

    .titleSection h4 {
        margin: 7px 0 0;
    }

    .pagination li a,
    .pagination li span {
        padding: 4px 0px;
        height: 30px;
        width: 30px;
        margin: 0 1px;
        font-size: 11px;
    }

    .dateSection span {
        font-size: 27px;
        line-height: 26px;
    }

    .dateSection {
        font-size: 9px;
        padding: 5px 0;
        height: 57px;
    }

    .gridImage {
        width: 46px;
    }

    .appointment {
        margin: 0 -5px;
    }

    .gridContent {
        width: 84%;
        padding: 0 5px 0 8px;
    }

    .gridTop {
        font-size: 12px;
    }

    .gridIconItem {
        width: 88px;
        font-size: 9px;
    }

    .gridIconItem.brownColor {
        width: 125px;
    }

    .gridList {
        clear: both;
        float: left;
    }

    .gridEdit {
        position: initial;
        font-size: 11px;
        float: right;
        margin-right: 8px;
    }

    .popupDiv {
        width: 97%;
    }

    .newAppoinmentRightList {
        padding: 0 5px;
        width: 50%;
        font-size: 10px;
        margin: 0 0 5px 0;
    }

    .mobileCountrySelect {
        width: 25%;
        padding-right: 5px;
    }

    .mobileNumberField {
        width: 75%;
    }

    .patientSearchitem {
        width: 48%;
    }

    .patientSearchitem svg {
        right: 7px;
        top: 7px;
    }

    .patientCount {
        font-size: 11px;
    }

    .cd-dot {
        padding: 2px 5px;
        font-size: 9px;
        line-height: 12px;
    }

    .innerContent {
        padding: 20px 0;
    }

    .titleHeading {
        padding: 0 0 15px;
        font-size: 17px;
    }

    .leftmenu {
        /* left: -285px; */
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        -ms-transition: 0.3s all;
        width: 285px;
        top: 52px;
        bottom: 0;
        display: none;
    }

    .leftMenuOpen .toggleOverlay,
    .settingopen .toggleOverlay {
        display: block;
    }

    .leftMenuOpen .leftmenu,
    .settingopen .settingMenu {
        left: 0;
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        -ms-transition: 0.3s all;
    }

    .leftmenu .logo {
        display: none;
    }

    .leftmenu ul {
        margin: 0;
        width: 284px;
        max-height: 100%;
    }

    .leftmenu li a {
        opacity: 0.9;
        padding: 8px 10px 8px 15px;
    }

    .leftmenu ul img {
        width: 18px;
    }

    .leftmenu ::-webkit-scrollbar,
    .settingMenu::-webkit-scrollbar {
        width: 1px;
    }

    .leftmenu li span {
        padding-left: 15px;
        font-size: 12px;
    }

    .leftmenu ul ul {
        padding: 0 0 0 25px;
    }

    body.leftMenuOpen,
    body.settingopen {
        overflow: hidden;
    }

    .rightSection {
        padding: 15px 5px;
        margin: 0;
    }

    .profileRightTag {
        width: 100%;
        display: block;
        margin: 8px 0 0;
    }

    .profileDetailContent {
        width: 100%;
        display: block;
    }

    .profileDetail {
        margin: 0 0 10px;
    }

    .treatmentPlanName {
        padding: 5px 0 0;
    }

    .treatmentPlanHead {
        font-size: 12px;
        float: left;
        min-height: auto;
        width: 100%;
    }

    body .practicementBtn,
    body .clinicalCancel,
    body .clinicalSave {
        padding: 3px 10px;
        font-size: 11px;
        margin: 0 0 3px 5px;
    }

    .treatmentPlanContent {
        clear: both;
    }

    .practiceManagementWrap {
        margin: 0 -5px;
    }

    .practiceManagementRow {
        padding: 0;
    }

    .profileImg img {
        width: 50px;
    }

    .profileImg {
        float: right;
        width: 25%;
    }

    .profileDetailList {
        font-size: 10px;
        line-height: 18px;
    }

    body .practiceLabel {
        font-size: 9px;
        width: 40%;
        text-align: left;
    }

    .practiceContentText {
        padding: 1px 0 0 7px;
        font-size: 9px;
        width: 60%;
    }

    .practiceContentText .inputField {
        width: 100%;
        margin: -2px 0 5px;
    }

    .mobileCountryCode {
        width: 22%;
    }

    .mobileNumberTxt {
        width: 78%;
        padding-left: 5px;
    }

    .checkSection label:before {
        top: 1px;
    }

    .profileCover {
        padding: 10px 5px;
    }

    .profileImgMobile {
        display: none;
    }

    .PregnancyDetailsTitle {
        padding: 0;
    }

    .profileDetailList h4 {
        font-size: 19px;
        padding: 10px 0;
    }

    .gynecologyContentDiv {
        font-size: 9px;
        line-height: 18px;
    }

    .gynecologyContentTxt {
        font-size: 9px;
    }

    .gynecologyContentDiv .inputField {
        margin: 0 10px 5px 0;
    }

    .gynecologyCheckArea label,
    .gynecologyLabel label {
        width: 58px;
        font-size: 9px;
    }

    .gynecologyCheckArea .inputField {
        width: 43px;
        padding: 2px 5px;
    }

    .gynecologyLabel label {
        width: 100%;
    }

    .gynecologyLabel {
        padding: 0 0 5px;
    }

    .gynecologyLabel .profileSpan {
        padding: 2px 10px 0 0;
    }

    .contentWraper {
        padding-bottom: 40px;
    }

    .clinicalLabel {
        width: 30%;
        font-size: 9px;
        text-align: left;
    }

    .clinicalField {
        width: 70%;
    }

    .clinicalFieldSubTxt,
    .clinicalDesTxt {
        font-size: 9px;
        margin: 0 0 3px;
    }

    body .inputFieldBottom {
        margin-bottom: 5px;
    }

    .clinicalDate {
        width: 100%;
        padding: 10px 0 0;
    }

    .clinicalDate label {
        font-size: 9px;
        width: 30%;
        padding-right: 10px;
        display: inline-block;
        vertical-align: top;
    }

    .clinicalDate .inputField {
        width: 70%;
    }

    .followUpTxt {
        font-size: 10px;
    }

    .gridContentLarge {
        font-size: 9px;
    }

    .appoinmentReason .elipsis {
        width: 82%;
    }

    .appoinmentHsptlName {
        font-size: 10px;
    }

    .treatmentComplaintLeft {
        width: 30%;
        font-size: 9px;
    }

    .treatmentComplaintRight {
        width: 70%;
    }

    .treatmentComplaintWrap {
        font-size: 9px;
        padding: 8px 5px;
    }

    .treatmentComplaintRight span {
        margin-bottom: 3px;
    }

    span.treatmentTAgDate {
        width: 27%;
    }

    span.treatmentTAgName {
        width: 73%;
    }

    body .treatmentItemLabel {
        font-size: 9px;
        width: 25%;
    }

    .practiceContentText .inputFieldXtraSmall {
        width: 50px;
        margin-right: 10px;
    }

    .clinicFollowUp .clinicContentList {
        padding: 10px 0;
    }

    .caeSheetRadioWrap {
        font-size: 9px;
    }

    .toggleOne {
        font-size: 10px;
    }

    body .practiceInputField {
        width: 115px;
    }

    .practiceManagementList {
        margin: 0 0 5px;
        font-size: 9px;
    }

    .treatmentTable table {
        /* width: 700px; */
        width: 100%;
    }

    /* .treatmentTable {
        overflow-x: scroll;
    } */
    .treatmentTable::-webkit-scrollbar,
    .treatmentListAddSection::-webkit-scrollbar {
        height: 5px;
    }

    .treatmentTable table td,
    .treatmentTable table th {
        font-size: 9px;
    }

    .dentalProName .elipsis {
        max-width: 120px;
        width: 80%;
    }

    .treatmentGrandTotal span,
    .treatmentContentList,
    .treatmentTxt .treatmentTxtLabel,
    .treatmentHead,
    .treatmentGrandByWrap,
    .treatmentTable table .dentalInvoiceAmount span {
        font-size: 10px;
    }

    .treatmentListAddSection .treatmentsList,
    .treatmentListAddSection .paymentSec {
        width: 700px;
    }

    .treatmentListAddSection {
        overflow-x: auto;
        margin: 0 -5px;
    }

    .treatmentPlanBtn {
        text-align: right;
    }

    .treatmentDrNameList ul li {
        padding: 5px;
    }

    .treatmentDrNameList {
        width: 180px;
    }

    .treatmentDst label {
        font-size: 11px;
    }

    .prescriptionRadio {
        margin: 5px 0 5px 15px;
        font-size: 11px;
    }

    .treatmentDivSecWrap {
        min-height: auto;
        height: auto;
        max-height: 290px;
    }

    .skechpadWrap {
        width: 100%;
    }

    .writePrePadCvr {
        padding: 10px 5px;
    }

    .skechpadChoose label {
        width: auto;
    }

    .skechpadChoose {
        font-size: 10px;
    }

    .palette {
        width: 20px;
        height: 17px;
    }

    body .clearButton {
        padding: 0 6px;
    }

    .treatmentWriteBody .writePrePadCvr {
        position: fixed;
        top: 0;
        z-index: 2;
        background: #fff;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .treatmentWriteBody {
        overflow: visible;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .treatmentWriteBody .skechpadTop {
        display: none;
    }

    .treatmentWriteBody .skechpadMobilePicker {
        display: block;
    }

    body .practiceGreenButton {
        padding: 3px 15px;
        font-size: 13px;
    }

    body .prescriptionLink {
        display: inline-block;
        color: #0d8be4;
        margin: 0 10px 0 0;
        font-size: 12px;
        text-decoration: underline;
    }

    .dentalProNameTop span.elipsis {
        font-size: 9px;
        max-width: 50px;
    }

    .inrSpan,
    .dentalProName .inVoiceTotal .inrSpan {
        width: 25px;
        font-size: 10px;
    }

    .inVoiceTotal .saVeBtn {
        padding: 6px 7px;
        font-size: 10px;
        height: 28px;
    }

    .treatmentGrandTotal input[type="text"] {
        font-size: 11px;
    }

    .treatmentTimelineType {
        font-size: 17px;
    }

    .treatmentTimelineCheck li {
        width: 50%;
        font-size: 10px;
    }

    .timelineListName {
        font-size: 14px;
    }

    .historyList {
        overflow-x: auto;
    }

    .timelineListName img {
        width: 18px;
    }

    .timelineListContent {
        padding: 10px;
    }

    .keyLeft {
        width: 100%;
        padding-bottom: 10px;
    }

    .keyRight {
        width: 100%;
        border-left: 0;
        border-top: 1px solid #000;
        padding-top: 10px;
    }

    .keyLeft li {
        font-size: 9px;
    }

    .keyRight .teethList span {
        font-size: 9px;
    }

    .keyRight .teethImg {
        width: 20px;
        height: 35px;
    }

    .keyRight .teethImg img {
        height: 25px;
    }

    .teethImg select {
        font-size: 9px;
        right: auto;
        width: 125px;
        height: 170px;
        left: -45px;
    }

    .caeSheetRadioWrap .radioSection {
        width: 50%;
        margin: 0 0 5px;
    }

    .teethImg select option {
        padding: 3px 3px;
    }

    .clinicalFieldSmall {
        margin: 0 0 8px;
    }

    .shareHospitalGroup {
        font-size: 11px;
    }

    .shareHospitalGroup .inputField {
        font-size: 11px;
        height: 28px;
    }

    body .printButton,
    body .dataButton {
        padding: 4px 15px;
        font-size: 12px;
    }

    .certificateCvrDiv textarea {
        font-size: 12px;
    }

    .treatmentNodataText {
        padding: 10px 0px;
        font-size: 13px;
        width: 300px;
        text-align: center;
    }

    .gallerySliderView .item {
        width: 95px;
        height: 95px;
    }

    .orthoView {
        width: 200px;
        height: 200px;
    }

    .orthoView span {
        width: 65px;
        height: 65px;
    }

    .xRayView {
        width: 103px;
        height: 201px;
    }

    .xRayView span {
        width: 98px;
        height: 98px;
    }

    .beforeView {
        width: 155px;
        height: 220px;
    }

    .galleryViewName {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .rowMargin .labelTextField {
        text-align: left;
    }

    .tagListingDiv {
        padding: 0 0 0 10px;
    }

    .settingMenu {
        width: 280px;
        min-height: auto;
        padding: 5px 0 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 54px;
        bottom: 0;
        left: -280px;
        transition: 0.3s all;
        -webkit-transition: 0.3s all;
        -ms-transition: 0.3s all;
        z-index: 2;
        overflow-y: auto;
    }

    .settingsRight {
        width: 100%;
        display: block;
        padding: 15px;
    }

    .dashboardTitle {
        font-size: 13px;
        margin: 0 0 15px;
    }

    .cardsettingLeft,
    .cardsettingRight {
        width: 100%;
        display: block;
    }

    .cardsettingList {
        margin: 0 0 8px;
        font-size: 10px;
    }

    .cardImageUpload {
        padding: 5px 0 0;
    }

    .cardsettingList label {
        margin: 1px 0 3px;
    }

    .cardsettingList .inputField {
        height: 28px;
    }

    .cardsettingList ul li .fa-pen {
        bottom: 5px;
        padding-top: 5px;
        height: 15px;
    }

    .practiceDetailsLogo {
        text-align: left;
        margin: 0 0 7px;
    }

    .practiceDetailsUpload {
        margin: 0 0 0 20px;
        display: inline-block;
    }

    body .logoChange {
        font-size: 10px;
    }

    .practiceDetailsLogo img {
        width: 43px;
    }

    .dashboardContentCenter,
    .dashboardContentLarge {
        width: 100%;
    }

    .exportList {
        width: 50%;
        font-size: 10px;
    }

    .simplifiedSection {
        padding: 10px 5px;
        margin-bottom: 10px;
        font-size: 10px;
    }

    .simplifiedCheck {
        margin: 0 10px 0 0;
    }

    .simplifiedSection .checkSection {
        margin: 0 3px 0px 0;
    }

    .simplifiedSectionItem {
        padding: 0 0 0 20px;
    }

    .simplifiedCheckList {
        margin: 0 0 10px;
    }

    .simplifiedCheckSplit {
        width: 50%;
    }

    .exportList .radioSection {
        margin: 0 3px 0 0;
    }

    .dashboardTableText {
        max-width: 180px;
    }

    .sendSmsWrap {
        margin: -15px;
    }

    .sendSmsCountDiv:after {
        border-right-width: 1px;
        right: 2px;
    }

    .sendSmsCountDiv {
        font-size: 9px;
    }

    .sendSmsCountDiv span {
        font-size: 12px;
    }

    .smsHeading {
        padding: 6px 0;
        font-size: 15px;
        clear: both;
    }

    .smsNotify,
    .smsNotifyError {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        font-size: 8px;
        padding: 5px 10px;
    }

    .smsLeftTab span {
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
    }

    .smsLeftTab li {
        height: 80px;
        font-size: 14px;
        width: 165px;
        height: 30px;
        text-align: right;
        display: inline-block;
    }

    .smsLeftTab {
        padding: 0 5px 20px;
        text-align: left;
    }

    .smsLeftTab li:first-child {
        height: 30px;
        width: auto;
    }

    .smsLeftTab li:first-child:after {
        display: none;
    }

    .sendSmsLeftSec {
        width: 100%;
    }

    .sendSmsRightSec {
        width: 100%;
        padding: 0 5px 10px;
    }

    .smsLeftTab li:after {
        top: 50%;
        bottom: auto;
        width: 175px;
        height: 3px;
        transform: translateY(-50%);
    }

    .smsTypeSectionItem {
        width: 33.333%;
        font-size: 12px;
    }

    .smsTypeSectionTop {
        padding: 0 0 10px;
    }

    .smsSubHeading {
        font-size: 10px;
    }

    .smsTypeSectionTag .react-tagsinput-input {
        min-width: 120px;
        padding: 2px 0px;
        font-size: 10px;
        height: 25px;
    }

    .smsTypeSectionTag .react-tagsinput-tag {
        height: 25px;
        font-size: 10px;
        padding: 4px 5px;
    }

    .smsButtonDiv .practicementBtn {
        padding: 3px 20px;
        font-size: 13px;
    }

    .tableSearchDiv {
        font-size: 10px;
    }

    .tableSearchDiv .inputField {
        width: 150px;
        height: 28px;
    }

    .tableSearchDiv .practicementBtnSmall {
        margin-right: 5px;
    }

    .tableContentSec {
        padding: 5px 3px;
        font-size: 10px;
    }

    .smsBody {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
    }

    .scheduleSmsDiv:after {
        display: none;
    }

    .scheduleSmsDiv {
        width: 100%;
        padding: 10px 0px;
    }

    .scheduleSmsDivHead {
        padding: 10px 0 5px;
        font-size: 14px;
    }

    .scheduleSMSRow label {
        min-width: 75px;
        font-size: 11px;
    }

    .dashboardtable {
        overflow-x: auto;
    }

    .dashboardtable table {
        /* width: 600px; */
        width: 100%;
    }

    body .dashButtonBorder {
        padding: 4px 20px;
        font-size: 12px;
        height: auto;
    }

    .dashboardSearchList {
        padding: 0 7px 7px;
    }

    .settingLeftTitle {
        font-size: 15px;
        padding: 5px 5px 10px;
    }

    .settingMenuList ul {
        font-size: 10px;
    }

    .settingMenuList ul li a {
        padding: 7px 5px;
    }

    .searchCount {
        margin: 0px -7px 0;
        font-size: 10px;
    }

    .dashboardNoData {
        font-size: 11px;
    }

    .teethArrow:after {
        border-width: 0 6.5px 9px 4.5px;
    }

    .teethArrow {
        right: 3px;
    }

    .keyRight .teethWrap {
        width: 75%;
    }

    .dailysummary {
        padding: 15px 10px;
        font-size: 9px;
    }

    .SummaryDiv label,
    .sampleDiv label {
        width: 35%;
    }

    .sampleDiv span,
    .SummaryDiv span {
        width: 65%;
    }

    .trackHistoryTableDiv {
        width: 100%;
        max-height: 250px;
    }

    .trackHistoryDiv {
        width: 100%;
    }

    .chartImg img {
        width: 15px;
    }

    .rightTagList {
        margin: 0 0 5px;
    }

    .rightTagTitle {
        margin: 0 0 7px;
        font-size: 11px;
    }

    .tagListingDiv .react-tagsinput-input {
        font-size: 10px;
        height: 28px;
        padding: 2px 5px;
    }

    body .borderButtonSmall {
        width: 100px;
        font-size: 10px;
        padding: 5px 0px;
        margin: 0 2px;
    }

    .webCamButton {
        margin: 0 0 15px;
    }

    .profileDetails {
        width: 75%;
        padding: 0 5px;
    }

    .profileImg .borderButtonSmall,
    .uploadButtonSmall label {
        width: 85px;
        font-size: 10px;
        padding: 5px 0px;
        margin: 0 2px 5px;
    }

    .treatmentPlanList {
        float: left;
    }

    .dashbrdMain .appoinmentWrapCvr {
        height: auto;
    }

    .pl_history .treatmentTable table td span {
        font-size: 10px;
    }

    body .topButton {
        font-size: 14px;
    }

    .dashboardBoxRight table {
        display: table;
    }

    .contentHead {
        font-size: 15px;
        padding: 0;
    }

    /* .pulseLogo img {
    width: 85px;
} */
    .floating-label label {

        top: 5px;
    }

    .prgnancyDetailsWrap {
        left: 0;

    }

    .doctorSearchFieldSec.drnameCalendar label {
        font-size: 10px;
    }

    .doctorSearchFieldSec.drnameCalendar .doctorSearchIcon {
        top: 25px;
    }

    .about_wrapper {
        padding: 80px 15px;
    }

    .main_wrappersec {
        padding-left: 15px;
    }

    .card-rvnuhead {
        font-size: 18px;
    }

    .notification_block {
        top: 30px;
    }

    .notification_block .video-icon,
    .notifi-icon {
        width: 25px;
        height: 25px;
    }

    .notifi-icon.video-icon svg {
        position: relative;
        top: -6px;
    }

    .specializationSelection .specializationClk {
        top: -12px;
    }

    .headerTopGreen .headerRightList {
        padding: 0px 8px 0;
    }

    .headerRightList {
        padding: 0px 5px;
    }

    #doctorMainSelection span {
        top: 0 !important;
    }

    .specializationSelection#doctorMainSelection .specializationClk {
        top: -5px;
    }

    .position-relative {
        position: absolute !important;
        top: 7px;
        left: 50px;
        width: 200px;
    }

    .logo-powerdbyinner {
        position: absolute;
        left: 50px;
        top: 25px;
    }
}

@media (max-width:800px) {
    .plpopup.plsmspopup {
        width: 98%;
        margin: 0 auto;
    }

    .popMobViewBlck .popMobView {
        position: absolute;
        top: 50%;
        left: 150px;
        margin: 0 auto;
        width: 45%;
    }

    .dashButtonDiv {
        padding: 15px 0px 15px;
    }

    body .practiceGreenButton {
        padding: 4px 10px;
        height: 34px;
    }

    .contentList ul li {

        font-size: 14px;
    }

    .contentWrap.members_receive h2 {

        font-size: 15px;
    }

    .loginForm {

        margin-bottom: 15px;
    }

    .loginOuter .iconTab {
        width: 48.5%;
    }

    .pulseLogo {
        top: -4px;
    }

    .patientright {
        padding: 15px;
    }

    .patientright .tagListingDiv {
        padding: 0 0 0 0px;
    }

    .gynecologyContentDiv {
        display: flex;
        flex-direction: column;
    }

    .gynecologyCheckArea label,
    .gynecologyLabel label {
        width: 70px;
    }
}

@media (max-width:768px) {
    .blockCalendarPopupSec .newAppoinmentRightList.timeSelect {
        width: 23%;
    }

    body.ReactModal__Body--open .ReactModal__Content {
        width: 55% !important;
    }

    .notification_block {
        right: 30%;
    }
}

@media (max-width:767px) {
    .icon.iconCalendar.calendar.plcalendar svg {
        margin-left: auto;
        right: 40px;
        margin-top: -43px;
    }

    .plAddPatient .profileForm .rowMargin {
        margin: -8px;
    }

    .plAddPatient .newAppoinmentRightList {
        width: 100%;
    }

    .plEmptyLabel {
        display: none;
    }

    .plResLang {
        margin-top: 20px;
    }

    .contentHead,
    .headerText {
        font-size: 30px;
    }

    .contentHead span,
    .loginSiteID .contentSubHead {
        font-size: 18px;
    }

    .topBlocks h4 {
        font-size: 16px;
    }

    .iconTabList,
    .topBlocks,
    .loginForm,
    .doc.blogVisitPage .blogVisitBlock .blogVisitPara {
        width: 100%;
    }

    .iconTab {
        padding: 10px 20px 30px 10px;
        min-height: auto;
    }

    .ploverlayy .plpopup.plpopupList {
        width: 95% !important;
        margin: 0 auto !important;
    }

    .plpopup {
        width: 95%;
        margin: 0 auto;
    }

    .contentWrap.members_receive .col-md-4,
    .contentWrap.members_receive .col-md-3,
    .contentWrap.members_receive .col-md-1 {
        width: 100%;
        margin: 0 auto;
        float: none;
    }

    .contentImg.contentPlus {
        width: 50px;
        margin: 0 auto;
    }

    .iconTab {
        margin: 0 0px 38px;
    }

    .FAQBodyWrap {
        width: 100%;
    }

    #loginSection {
        background: #fff;
        width: 60%;
    }

    .loginOuter #loginDiv {
        display: flex;
        justify-content: center;
    }

    .loginOuter .iconTab {
        width: 100%;
    }

    .pulseContentWrap .contentHead {
        padding: 10px 0 15px;
    }

    #loginSection {
        top: 75%;
    }

    .pulseLeftImg img {
        width: 60%;
    }

    .firstLeft ul li {
        font-size: 15px !important;
    }

    .printResponsive {
        padding: 15px;
    }

    .pulseLogo .footerpurple_logoimg {
        width: 210px;
        height: 64px;
    }

    .footerida_wraper {
        display: inline-grid;
    }

    .footer_subwraper {
        margin-top: 0;
    }

    .footer_subtext {
        padding-right: 15px;
    }

    .footersubtextLink {
        font-size: 12px;
    }

    .footer_subdflex {
        justify-content: center;
    }

    .pulseLogoright img {
        width: 110px;
    }

    .footerContent_padd {
        padding: 10px;
    }

    .profileImg {
        float: left;
    }

    .gridList .gridEdit {
        width: auto;
        text-align: right;
        position: relative;
    }

    .appointmentFormList select {
        width: 100% !important;
    }

    /* .plAppointmenPopup {
        width: 95%;
        margin: 0 auto;
    } */
    #formId .popUpClose {
        font-size: 30px;
    }

    .addPatientMainpg .newAppoinmentButton {
        position: unset;
    }

    .addPatientMainpg .appointmentForm {
        height: auto;
    }

    .yearOfBirthInfo {

        left: 96px;
    }

    .react-datepicker-popper {
        z-index: 9999 !important;
    }

    .fc .fc-toolbar {
        display: block !important;
    }

    .fc .fc-toolbar.fc-header-toolbar {
        height: 100px !important;
    }

    .gridIconItem {
        width: 100%;
        font-size: 11px;
    }

    .ptList .gridImage img {
        width: 40px;
        height: 40px;
    }

    .hospitalName {
        position: relative;
        top: auto;
        right: auto;
    }

    .specializationSelection,
    .notification_block {
        display: none;
    }

    .headerRight {
        top: 15px;
    }

    .logo-powerdbyinner {
        left: 60px;
    }

    .changeImage.changeImgPro.AddPatientImgUpload {
        margin: 5px -3px;
    }

    body .borderButton,
    body .borderButtonCancel,
    body .borderButtonLarge {
        margin-bottom: 8px;
    }

    #newUsertextId {
        left: 86px;
    }
}

@media (max-width:640px) {
    #loginSection {
        width: 80%;
    }

    .loginOuter .iconTab {
        width: 100%;
        margin: 0px 0px 45px;
    }

    .notification_block {
        right: 35%;
    }

    .newAppoinmentRightList {
        padding: 0 12px;
        width: 100%;
    }

    .container-sm,
    .container {
        max-width: 95%;
        padding: 0;
        margin: 0 auto;
    }
}

@media (max-width:570px) {

    .pl_sms_right,
    .pl_sms_left {
        width: 100%;
    }

    .dashbrdMain .dashBoardContent {
        width: 100%;
    }

    .dashboardLeft .appoinmentSatusWrap span {
        width: 100%;
    }

    .practiceManagementForm {
        padding: 2px 10px;
    }

    .inventoryStockDiv .col-lg-4 {
        padding-left: 0px;
        padding-right: 0;
    }

    .contentHead,
    .headerText,
    .loginOuter .contentHead {
        font-size: 20px;
    }

    .serviceListMain {
        width: 100%;
    }

    .serviceInfoWrap {
        padding: 10px;
    }

    .actionButtons {
        display: block;
    }

    body .inputField,
    body .textareaField {
        height: 30px
    }

    .borderButton.borderButtonLarge.legoButton_green,
    .borderButtonCancel.borderButtonLarge {
        width: 100%;
    }

    .borderButtonCancel.borderButtonLarge {
        margin: 0;
    }

    .contentHead.pulseLoginHead {
        padding-top: 25px !important;
    }

    .loginOuter .contentHead span {
        padding-top: 15px;
    }

    .loginOuter .contentHead {
        padding-top: 25px;
    }

    .loginOuter .contentHead {

        padding: 10px 0 25px;
    }

    .loginOuter #loginDiv {

        justify-content: center;
        padding: 0;
    }

    #loginSection {
        width: 90%;
        margin: 0 auto;
        /* top: 30%; */
    }

    .contentHead span,
    .loginSiteID .contentSubHead {
        font-size: 15px;
    }

    .pulseLogo {
        top: -3px;
    }

    body.ReactModal__Body--open .ReactModal__Content {
        width: 98% !important;
    }

    .blockCalendarPopupSec .newAppoinmentRightList {
        width: 100%;
    }

    .doctorSearchFieldSec.drnameCalendar input,
    .doctorSearchFieldSec.drnameCalendar input::placeholder {

        margin-bottom: 15px;
    }

    .blockCalendarPopupSec .col-md-6,
    .doctorSearchFieldSec.drnameCalendar {
        width: 100%;
    }

    .patientInfoList.appointmentNumberSearch {
        min-width: 100%;
    }

    .notification_block {
        right: 40%;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
        margin: 1.75rem auto;
    }
}

@media (max-width: 480px) {
    #loginSection {
        top: 50%;
    }

    .pulseLeftImg img {
        width: 45%;
    }

    .loginOuter .pulseLeftImg img {
        margin-top: 30px;
    }

    .notification_block {
        right: 50%;
    }

    .pu_modal_content.plCalendarModal {
        height: 300px;
        overflow: hidden;
        overflow-y: auto;
    }

    .pu_modal_content select,
    .pu_modal_content .form-group {
        width: 100%;
    }
}

@media (max-width:425px) {
    .mobileCountrySelect .formInput {
        width: 100%;
    }

    .newAppoinmentRightList {
        width: 100%;
    }

    .appointmentForm {
        min-height: 300px;
        overflow: hidden;
        overflow-y: auto;
        height: auto !important;
        max-height: 300px;

    }

    .addpatientContent .col-md-12 .row.appointmentForm {
        height: auto;
        min-height: initial;
    }

    .innerContent.plAppContent .addpatientContent .appointmentForm {
        height: auto;
    }

    .contentWraper {
        padding-bottom: 30px;
    }

    .Appointmentjsx .customizationPop .newAppoinmentButton {
        padding-bottom: 0px;
        position: absolute;
        bottom: 0px;
        margin-bottom: 15px;

    }

    .customizationPopContentSec .container {
        padding: 0px !important;
    }

    .appInfoWrap {
        width: 95%;
    }

    .searchSection.plAppInfo {
        width: 100%;
    }

    .Appointmentjsx .customizationPop .newAppoinmentButton {
        left: auto;
        right: 15px;
    }

    body .container {
        width: 100% !important;
        padding: 15px !important;
    }

    .newAppoinmentButton {
        position: absolute;
        bottom: 0;
        right: 15px;
    }

    .pagination {

        width: 100%;
        justify-content: end;
        padding-top: 10px;
    }

    .headerRight {
        top: 0px;
    }

    .ptList .gridImage img {
        width: 50px;
        height: 50px;
    }

    #doctorMainSelection span {
        top: -8px !important;
    }

    .bulletinBoardHead .bltHeading,
    .dashboard-right .blogVisitHead a,
    .dashboard-right .blogVisitHead a {
        font-size: 22px;
    }

    .headerRight {
        top: 18px;
    }

    .headerTop {
        height: 55px;
    }

    body .borderButton,
    body .borderButtonCancel {
        margin: 0 0 5px 10px;
    }

    .pagination li a,
    .pagination li span {
        margin: 4px 2px;

    }

    .Appointmentjsx .paginationSection {
        width: 100%;
        float: left;
    }

    #spanAddSupplier {
        font-size: 13px !important;
    }

    .plAppointmenPopup .mobileCountrySelect .formInput {
        width: 100%;
    }

    .Appointmentjsx .appointmentGridList {
        border: 0px solid #d4d4d4;
    }
}

@media (max-width:393px) {
    .notification_block {
        right: 58%;
    }

    body .container {
        width: 100% !important;
        padding: 5px !important;
    }
}

@media (max-width :375px) {
    #loginSection {
        top: 56%;
    }

    .notification_block {
        right: auto;
        left: 85px;
    }
}

@media (max-width :280px) {

    .contentHead,
    .headerText,
    .loginOuter .contentHead {
        font-size: 17px;
    }

    .contentHead span,
    .loginSiteID .contentSubHead {
        font-size: 12px;
    }
}